.merchPage {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.section {
  margin-bottom: 40px;
}

.subTitle {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.text {
  font-size: 1rem;
  line-height: 1.6;
}

.shopifyButton {
  margin-top: 20px;
}

.ctaSection {
  text-align: center;
  margin-top: 40px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.ctaButton {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.2rem;
  color: #fff;
  background-color: #e7594b;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 15px;
  transition: background-color 0.3s;
}

.ctaButton:hover {
  background-color: #d05044;
}
