/* src/components/Sidebar/Sidebar.module.css */
.sidebar {
    background-color: #820117;
    color: #ffffff;
    padding: 15px;
    width: 170px;
  }
  
  .sidebar h2 {
    background-color: #421117;
    padding: 5px;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 1;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar li {
    margin-bottom: 10px;
    display:flex;
    line-height: 1;
  }
  
  .sidebar a {
    color: #ffffff;
    text-decoration: none;
  }
  
  .sidebar a:hover {
    text-decoration: underline;
  }
  
  .montageImage {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }
  