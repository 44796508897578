/* src/styles/walletOverrides.css */

/* Example: Customize the dropdown background and text color */
.wallet-adapter-dropdown {
    color: #333333 !important;           /* Dark text color */
    padding: 23px!important; 
  }
  
  /* Example: Customize the dropdown item hover effect */
  .wallet-adapter-dropdown-item:hover {
    background-color: #e0e0e0 !important; /* Slightly darker gray on hover */
    color: #000000 !important;           /* Black text on hover */
  }
  
  /* Example: Customize the wallet button */
  .wallet-adapter-button {
    background-color: #86c7f6!important; /* Green background */
    color: #ffffff !important;            /* White text */
    border-radius: 8px !important;        /* Rounded corners */
  }
  
  .wallet-adapter-button:hover {
    background-color: #45a049 !important; /* Darker green on hover */
  }
  /* src/components/WalletBalances.module.css */

.walletBalances {
  background-color: #f0f0f0;
  padding: 16px;
  border-radius: 8px;
}

.loading {
  display: flex;
  align-items: center;
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-top: 2px solid #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 8px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.error {
  color: red;
  margin-bottom: 8px;
}

.balanceItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.balanceLabel {
  font-weight: bold;
}

.balanceValue {
  /* Additional styling if needed */
}
