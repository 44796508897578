/* src/components/Header/Header.module.css */
:root {
  --primary-color: #e30017;
  --nav-background: rgba(255, 255, 255, 0.8);
  --nav-text-color: #000000;
  --nav-hover-bg: var(--primary-color);
  --nav-hover-text-color: #ffffff;
  --header-padding: 0 0px;
}


.header {
  position: relative; /* Establishes a containing block for absolutely positioned nav */
  display: flex;
  align-items: center;
  padding: var(--header-padding);
  height: var(--header-height);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
}
  
  .logoContainer {
    flex: 0 0 auto;
  }
  
  .logo {
    width: 170px;
    height: auto; /* Maintains aspect ratio */
    display: block; /* Removes any inline spacing */
  }
  
  .navContainer {
    flex: 0 0 auto;
  }

  .nav {
    flex: 1;
    margin-left: 20px;
  }
  
  .nav ul {
    list-style: none;
  display: flex;
  flex-direction: column; /* Change to vertical list */
  gap: 10px; /* Space between nav items */
  margin: 0;
  padding: 0;
  }
  
  .nav a {
    text-decoration: none;
    color: #000;
    background-color: #86c7f6; /* Example hover background */
    font-weight: bold;
    padding: 2px 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .nav a:hover {
    background-color: #e30017; /* Example hover background */
    color: #ffffff; /* Example hover text color */
    border-radius: 4px; /* Rounded corners on hover */
  }
  
  .ohioLogoContainer {
    flex: 1; /* Prevents Ohio logo from stretching initially */
    margin-left: auto; /* Pushes Ohio logo to the far right */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #e60000;
  }
  
  .ohioLogo {
    width: 403px;
  height: 94px; /* Maintain aspect ratio */
  object-fit: contain; /* Ensures the image scales properly */
  }
  .walletconnect {
    flex: 1; /* Prevents Ohio logo from stretching initially */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #e60000;
  }
  wallet-adaptor-dropdown{
    padding:23px;
  }