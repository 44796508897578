.rizzidentPage {
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9); /* Light transparency for readability */
    color: #333;
    font-family: 'Arial', sans-serif;
  }
  
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .section {
    margin-bottom: 30px;
  }
  
  .subTitle {
    font-size: 1.8rem;
    font-weight: bold;
    color: #007acc; /* Optional color pop for section headers */
    margin-bottom: 10px;
  }
  
  .text {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 10px;
  }
  