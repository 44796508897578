.governmentPage {
    padding: 20px;
    position: relative;
    color: #333;
  }
  .governmentPage::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/src/assets/images/flag1-trans.gif');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    opacity: 0.2; /* Adjust transparency */
    z-index: 1; /* Layer below content */
  }
  
  .governmentPageContent {
    position: relative;
    z-index: 2; /* Layer above the background */
    background-color: rgba(255, 255, 255, 0.2); /* Light background behind text */
  padding: 5px;
  border-radius: 8px;
  line-height: 1.2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }
  .pageTitle {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    color: #ff4500;
    margin-bottom: 20px;
  }
  
  .intro {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 20px;
    color: #222; /* Ensure high contrast for readability */
  }
  
  .sectionTitle {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 20px;
    color: #ff4500;
  }
  
  .sectionText {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #222; /* Ensure high contrast for readability */
  }
  
  .declarationPoints {
    margin-left: 20px;
    color: #444; /* Subtle contrast for bullet points */
  }
  
  .grandSigmaMessage .quote {
    font-size: 1.5rem;
    line-height: 1.8;
    margin: 20px 0;
    border-left: 4px solid #ff4500;
    padding-left: 10px;
    color: #222; /* Ensure readability for quotes */
    background-color: rgba(255, 255, 255, 0.8); /* Add a semi-transparent background to quotes */
  }
  
  .signature {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 10px;
    color: #333;
  }
  
  .governmentMoves ul {
    margin: 20px 0;
  }
  
  .featuredContent ul {
    margin: 20px 0;
  }
  
  .featuredContent ul li {
    margin: 10px 0;
  }
  
  .featuredContent ul li a {
    color: #0066cc;
    text-decoration: none;
  }
  
  .featuredContent ul li a:hover {
    text-decoration: underline;
  }
  