/* src/components/Footer/Footer.module.css */
.footer {
    background-color: #000000;
    color: #ffffff;
    padding: 10px 20px;
    position: relative;
  }
  
  .navLinks {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .navLinks a {
    color: #ffffff;
    text-decoration: none;
    margin: 0 5px;
    font-size: 14px;
  }
  
  .navLinks a:hover {
    text-decoration: underline;
  }
  
  .decorations {
    display: flex;
  }
  
  .leftDecoration {
    background-color: #e30017;
    width: 170px;
    height: 50px;
  }
  
  .rightDecoration {
    background-color: #820117;
    flex: 1;
  }
  