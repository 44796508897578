/* src/styles/global.css */

/* CSS Reset (optional but recommended) */
*, *::before, *::after {
    margin: 0;
    padding: 0;
  }
  
  /* Typography */
  body {
    font-family: Arial, sans-serif;
    line-height: .7;
    color: #333;
    background-color: #f9f9f9;
  }
  
  h1, h2, h3, h4, h5, h6 {
    color: #e30017;
    margin-bottom: 10px;
  }
  
  p {
    margin-bottom: 15px;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  /* Layout Containers */
  .container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px;
  }
  
  /* Utility Classes */
  .text-center {
    text-align: center;
  }
  
  .mt-20 {
    margin-top: 20px;
  }
  
  .mb-20 {
    margin-bottom: 20px;
  }
  
 /* Home Page Styles */
.home {
  padding: 0px;
  background-color: #f9f9f9;
}

.home h1 {
  color: #e30017;
}

.home p {
  font-size: 16px;
  color: #333;
}

.features-title {
    position: absolute;
    top: 10px; /* Adjust as needed */
    left: 10px; /* Adjust as needed */
    z-index: 2; /* Ensure it sits above the image */
    background-color: rgba(128, 141, 192, 0.8); /* Semi-transparent background for readability */
    padding: 10px;
    border-radius: 4px;
  }
  
  /* Features List */
  .features-list {
    position: absolute;
    top: 85px; /* Adjust based on the height of the h2 */
    left: 15px; /* Align with the h2 */
    z-index: 2;
    background-color: rgba(128, 141, 192, 0.8);
    padding: 10px;
    border-radius: 4px;
    list-style: none;
  }
  
  /* src/pages/Forms.module.css */
.forms {
  padding: 20px;
}

.forms h1 {
  color: #e30017;
}

.forms ul {
  list-style: none;
  padding: 0;
}

.forms li {
  margin: 10px 0;
}

.forms a {
  color: #007bff;
  text-decoration: none;
}

.forms a:hover {
  text-decoration: underline;
}

/* src/pages/Contacts.module.css */
.contacts {
  padding: 20px;
}

.contacts h1 {
  color: #e30017;
}

.contacts p {
  font-size: 16px;
  color: #333;
}

.contacts ul {
  list-style: none;
  padding: 0;
}

.contacts li {
  margin: 10px 0;
}

/* src/pages/PressRoom.module.css */
.pressRoom {
  padding: 20px;
}

.pressRoom h1 {
  color: #e30017;
}

.pressRoom p {
  font-size: 16px;
  color: #333;
}

/* src/pages/Search.module.css */
.search {
  padding: 20px;
}

.search h1 {
  color: #e30017;
}

.search form {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.searchInput {
  flex: 1;
  padding: 8px;
  font-size: 16px;
}

.searchButton {
  padding: 8px 16px;
  background-color: #e30017;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.searchButton:hover {
  background-color: #c20013;
}

/* src/pages/Privacy.module.css */
.privacy {
  padding: 20px;
}

.privacy h1 {
  color: #e30017;
}

.privacy p {
  font-size: 16px;
  color: #333;
}

/* src/pages/Feedback.module.css */
.feedback {
  padding: 20px;
}

.feedback h1 {
  color: #e30017;
}

.feedback p {
  font-size: 16px;
  color: #333;
}

/* Container for Sidebar and Main Content */
.container {
  display: flex;
  flex-direction: row;
  min-height: calc(80vh - 200px); /* Adjust based on Header and Footer height */
  margin: 0; /* Align container to the left */
  width: 100%; /* Span full width */
}

/* Sidebar Styles (if not already defined) */
.sidebar {
  width: 170px; /* Fixed width for sidebar */
  background-color: #820117;
  color: #ffffff;
  padding: 15px;
  flex-shrink: 0; /* Prevent sidebar from shrinking */
}

/* Main Content Styles */
.main-content {
  flex: 1; /* Takes up remaining space */
  padding: 0px;
  background-color: #f9f9f9;
  background-image: url('/src/assets/images/website-background3.png'); /* Adjust the path */
  background-size: 600px;
  background-repeat: no-repeat;
  background-attachment: fixed; /* Keeps the image static on scroll */
  background-position: 180px 100px;
  opacity: 0.9; /* Adjust the transparency level */
}
.main-content::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.01); /* Adjust the color and transparency */
  pointer-events: none; /* Ensures it doesn't block interactions */

}

.custom-wallet-button {
  background-color: #00FFA3;
  color: #000;
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.custom-wallet-button:hover {
  background-color: #00C78F;
}
.swap-page {
  text-align: center;
  padding: 20px;
}

.swap-widget-container {
  margin: 0 auto;
  max-width: 800px;
  height: 600px;
}

.swap-widget {
  width: 100%;
  height: 100%;
  border: none;
}