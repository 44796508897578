/* src/components/WalletBalances.module.css */

.walletBalances {
    background-color: #f0f0f0;
    padding: 16px;
    border-radius: 8px;
    color:black;
    line-height: 1.4em;
  }
  
  .loading {
    display: flex;
    align-items: center;
  }
  
  .spinner {
    width: 16px;
    height: 16px;
    border: 2px solid #ccc;
    border-top: 2px solid #333;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-right: 8px;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .error {
    color: red;
    margin-bottom: 8px;
  }
  
  .balanceItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    color: black;
  }
  
  .balanceLabel {
    font-weight: bold;
    color: black;
  }
  
  .balanceValue {
    /* Additional styling if needed */
  }
  