/* src/components/SecondaryNavigation/SecondaryNavigation.module.css */
.secondaryNav {
    background-color: #000000;
    padding: 5px 0;
  }
  
  .secondaryNav ul {
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 30px;
    margin: 0;
    padding: 0;
  }
  
  .secondaryNav a {
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
  }
  
  .secondaryNav a:hover {
    text-decoration: underline;
  }
  