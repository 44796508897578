/* src/components/MainContent/MainContent.module.css */
.mainContent {
    display: flex;
    gap: 0px;
    padding: 0px;
    position: relative;
    width: 500px;
  }
  
  .leftColumn {
    flex: 1;
    position: relative; 
  
    display: flex;
    line-height: 1.6;
  }
  
  .leftColumn h2 {
    background-color: #808dc0;
    padding: 0px;
    color: #ffffff;
  }
  
  .leftColumn ul {
    list-style: none;
    padding: 0;
  }
  
  .leftColumn li {
    margin: 0px 0;
  }
  
  .leftColumn a {
    color: #ffffff;
    text-decoration: none;
  }
  
  .leftColumn a:hover {
    text-decoration: underline;
  }
  
  .montageImage {
    width: 100%;
    height: 190px;
    margin-top: 110px;
    z-index: 1;
    display: block;
  }
  
  .rightColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-top: 35px;
    padding: 225px 0px;
    font-size: 1.5rem; /* Large font size for easy reading */
    font-weight: bold; /* Make text bold */
    color: #333; /* Dark color for contrast */
    line-height: 1.6; /* Increase line spacing for readability */
  }
  .right-column a {
    display: block; /* Ensures anchors take up full width if needed */
    margin: 0;
    padding: 0;
  }
  .governorImage {
    width: 100%;
    height: auto;
  }
  
  .topImage, .linkImage {
    width: 100%;
    height: auto;
  }
  
  .squareImageContainer {
    position: fixed;
    top: 160px;
    right: 20px;
  }
  
  .squareImage {
    width: 170px;
    height: 94px;
  }
  