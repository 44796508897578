.exploreOhioPage {
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.9);
  font-family: 'Arial', sans-serif;
  color: #333;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 40px;
}

.subTitle {
  font-size: 1.8rem;
  font-weight: bold;
  color: #007acc;
  margin-bottom: 10px;
}

.text {
  font-size: 1.2rem;
  line-height: 1.8;
}

.mapContainer {
  margin-top: 20px;
  text-align: center;
}

.mapImage {
  width: 100%; /* Make the map image responsive */
  max-width: 800px; /* Optional: Limit max size */
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
