.artsPage {
    padding: 20px;
    margin: 0 auto;
    max-width: 800px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  .pageTitle {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .contentSection {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555;
  }
  
  .contentSection p {
    margin-bottom: 20px;
  }
  
  .contentSection strong {
    color: #000;
  }
  